<template>
	<div
		class="full-height"
	>
		<div class="">
			<h6 class="text-left color-identify under-line-identify">월세 결제 정보</h6>
			<table
				class="table th-left td-right"
			>
				<col width="120px"/>
				<col width="auto" />
				<tbody>
				<tr>
					<th>사용자</th>
					<td>{{ item_detail.depositor }}</td>
				</tr>
				<tr>
					<th>사용자 연락처</th>
					<td><input
						v-model="item.userHp"
						type="number"
						class="input-box"
						placeholder="링크결제를 전송할 연락처를 입력하세요"
						:rules="[rules.max(item, 'userHp', 11)]"
					/>
					</td>
				</tr>
				<tr>
					<th>수수료({{  item_detail.feeRate }}%)</th>
					<td>{{ item_detail.fee | makeComma }}</td>
				</tr>
				<tr>
					<th>납입금액</th>
					<td>{{ item_detail.amount | makeComma }}</td>
				</tr>
				<tr>
					<th>결제금액</th>
					<td>{{ totalPrice | makeComma}}</td>
				</tr>
				</tbody>
			</table>
		</div>
		<div
			class="mt-10"
		>
			<button
				@click="doKakao"
				class="box bg-identify pa-5-10 mr-10"
			>결제 링크 전송</button>
			<button
				@click="$emit('cancel')"
				class="pa-5-10 bg-identify-outline"
			>취소</button>
		</div>
	</div>
</template>

<script>
	import {Axios} from "@/resources/axios/axios";
	import { rules } from "@/resources/rules/rules";

	export default {
		name: 'MonthlyPaymentkakao'
		,props: ['item', 'user', 'service', 'type']
		,data: function(){
			return {
				rules: rules
				,Axios: Axios
				,program: {
					name: '카카오톡 결제요청'
					,title: false
					,top: false
					,bottom: false
				}
				,item_detail: {}
			}
		}
		,computed: {
			totalPrice: function(){
				let price = 0

				price = Number(this.item_detail.fee) + Number(this.item_detail.amount)

				return price
			}
		}
		,methods:{
			getData: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'get'
						,url: 'monthly/payment/' + this.item.idx
						,data: {
							service: this.service
						}
					})

					if(result.success){
						this.item_detail = result.data
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$emit('offLoading')
				}
			}
			,getScheduleData: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'get'
						,url: 'monthly/log/detail'
						,data: {
							idx: this.item.idx
							,idxType: this.item.idxType
							,paymentDate: this.item.day
							,service: this.service
						}
					})

					if(result.success){
						this.item_detail = result.data
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$emit('offLoading')
				}
			}
			,doKakao: async function(){
				try{
					this.$emit('onLoading')
					const result = await this.Axios({
						method: 'post'
						,url: 'monthly/sms/send'
						,data: {
							monthlyPaymentTransactionIdx: this.item.idx
							,userHp: this.item.userHp
							,service: this.service
						}
					})

					if(result.success){
						this.$emit('setNotify', { type: 'success', message: result.message})
						this.$emit('success')
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(e){
					alert(11)
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$emit('offLoading')
				}
			}
		}
		,created() {
			if(this.type){
				this.getScheduleData()
			}else{
				this.getData()
			}
		}
	}
</script>