<template>
	<div class="full-height">
		<div class="justify-space-between">
			<div
				class="flex-1 mr-10 position-relative"
			>
				<h6 class="text-left color-identify under-line-identify">결제내역</h6>
				<table
					class="table th-left td-right"
				>
					<col width="160px"/>
					<col width="auto" />
					<tbody>
					<tr>
						<th>거래일시</th>
						<td>
              <span v-if="item_detail.approvalDate">{{ item_detail.approvalDate }}</span>
              <span v-else>-</span>
            </td>
					</tr>
					<tr>
						<th>승인번호</th>
            <td>
              <span v-if="item_detail.approvalNumber">{{ item_detail.approvalNumber }}</span>
              <span v-else>-</span>
            </td>
					</tr>
					<tr>
						<th>카드번호</th>
						<td>{{ item_detail.cardNumber }}</td>
					</tr>
					<tr>
						<th>카드종류</th>
						<td>{{ item_detail.cardName}}</td>
					</tr>
					<tr>
						<th>할부개월</th>
						<td>{{ item_detail.installment }}</td>
					</tr>
					<tr>
						<th>공급가</th>
						<td class="text-right">{{ item_detail.supplyPrice | makeComma }}원</td>
					</tr>
					<tr>
						<th>부가세</th>
						<td class="text-right">{{ item_detail.vatPrice | makeComma }}원</td>
					</tr>
					<tr>
						<th>총금액</th>
						<td class="text-right">{{ item_detail.totalPrice | makeComma }}원</td>
					</tr>
					</tbody>
				</table>

				<div
					v-if="item_detail.is_fail"
					class="position-absolute wrap-card-fail"
				>
					<div class="bg-card-fail"></div>
					<div class="pa-10 box-card-fail position-absolute">
						<div class="full-height flex-column justify-center color-white line-height-200">

							<div>
								<v-icon
									x-large
									class="color-icon"
								>mdi mdi-alert-circle</v-icon>
							</div>

							<p class="mt-30 size-px-16 font-weight-bold">사용자의 신용카드 결제가 실패하였습니다</p>

							<p class="mt-30">사용자의 연락처로 결제가 실패된 것을 알려주세요</p>
							<div class="mt-10">
								<v-icon
									class="color-icon"
								>mdi mdi-chevron-down</v-icon>
							</div>
							<p class="mt-10">`카톡결제`를 통해 사용자에게 별도의 결제 링크를 전달하세요</p>
							<div class="mt-10">
								<v-icon
									class="color-icon"
								>mdi mdi-chevron-down</v-icon>
							</div>
							<p class="mt-10">사용자는 카톡 또는 SMS로 전달 받은 결제 링크를 통해 <br/> 다른 신용카드로 결제가 가능합니다.</p>
						</div>
					</div>
				</div>
			</div>
			<div
				class="flex-1 mr-10"
			>
				<h6 class="text-left color-identify under-line-identify">납입정보</h6>
				<table
					class="table th-left td-right"
				>
					<col width="160px"/>
					<col width="auto" />
					<tbody>
					<tr>
						<th>납입일</th>
						<td>{{ item_detail.paymentDate }}</td>
					</tr>
					<tr>
						<th>사용자</th>
						<td>{{ item_detail.userName }}</td>
					</tr>
					<tr>
						<th>사용자 연락처</th>
						<td>{{ item_detail.userHp}}</td>
					</tr>
					<tr>
						<th>예금주 은행</th>
						<td>{{ item_detail.bankName }}</td>
					</tr>
					<tr>
						<th>예금주 계좌번호</th>
						<td>{{ item_detail.account }}</td>
					</tr>
					<tr>
						<th>예금주</th>
						<td>{{ item_detail.lessor }}</td>
					</tr>
					<tr>
						<th>입금자</th>
						<td>{{ item_detail.depositor }}</td>
					</tr>
					<tr>
						<th>수수료+부가세({{  item_detail.feeRate }}%)</th>
						<td>{{ item_detail.fee }}원</td>
					</tr>
					<tr>
						<th>납입금액</th>
						<td>{{ item_detail.amount | makeComma }}원</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="mt-10 text-left"><label><input type="checkbox" /> 납입완료시 위 내용을 꼭 확인하세요</label></div>

		<div
			slot="modal-bottom"
			class="pa-10 justify-center"
		>
			<button
				v-if="item_detail.proceed && !item_detail.paymentState"
				class="pa-10 bg-identify-outline mr-10"
				@click="$emit('toKakao')"
			>카톡결제</button>
			<button
				v-if="item_detail.paymentState && !item_detail.state"
				class="pa-10 bg-green mr-10"
				@click="$emit('doConfirm')"
			>납입완료</button>
			<button
				class="pa-10 bg-identify"
				@click="$emit('close')"
			>확인</button>
		</div>
	</div>
</template>

<script>
import {Axios} from "@/resources/axios/axios";

export default {
	name: 'MonthlyPaymentDetail'
	,components: {}
	,props: ['user', 'item', 'service', 'type']
	,data: function(){
		return {
			Axios: Axios
			, program: {
				name: '월세 심사 상세'
				,title: false
				,top: false
				,bottom: false
			}
			,item_detail: {

			}
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'monthly/payment/' + this.item.idx
					,data: {
						service: this.service
					}
				})

				if(result.success){
					this.item_detail = result.data
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getScheduleData: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'monthly/log/detail'
					,data: {
						idx: this.item.idx
						,idxType: this.item.idxType
						,paymentDate: this.item.day
						,service: this.service
					}
				})

				if(result.success){
					this.item_detail = result.data
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
	}
	,created() {

		if(this.type){
			this.getScheduleData()
		}else{
			this.getData()
		}
	}
}
</script>

<style>
	.wrap-card-fail { width: 100%; height: 100%; left: 0; top: 0}
	.bg-card-fail { width: 100%; height: 100%; background-color: black; opacity: 0.75}
	.box-card-fail { width: 100%; height: 100%; left: 0; top: 0; z-index: 99}
</style>